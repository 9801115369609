import { WithLangs } from '@common/typescript/objects/Page';
import { Lang } from '@common/typescript/objects/Lang';

import { Page, PageInfo } from '@commonTuna/react/objects/Page';

export const getPageInfo = (item: WithLangs<PageInfo>): PageInfo | undefined => {
	return item.langs.length > 0 ? (item.langs.find((langItem) => langItem.language === Lang.En) || item.langs[0]) : undefined;
};

export const getPageShortName = (item: Page): string => {
	return item.shortNameEn || item.name;
};
